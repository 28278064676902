import { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from "react"
import api from "src/utils/api"

type Profile = {
  username: string
  full_name: string
  bio: string | null
  avatar: string | null
}

export const AuthContext = createContext<{
  profile: Profile | null
  setProfile: Dispatch<SetStateAction<Profile | null>>,
  fetching: boolean
  setFetching: Dispatch<SetStateAction<boolean>>
}>({
  profile: null,
  setProfile: () => {/**/},
  fetching: true,
  setFetching: () => {/**/}
})

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [profile, setProfile] = useState<Profile | null>(null)
	const [fetching, setFetching] = useState<boolean>(true)

	return (
		<AuthContext.Provider value={{ profile, setProfile, fetching, setFetching }}>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuth = () => {
  const { setProfile, profile, setFetching, fetching } = useContext(AuthContext)

  const getProfile = useCallback(
    async () => {
      try {
        setFetching(true)
        const response = await api.get<{ data: { result: { user: Profile } } }>('/profile')
        setProfile(response?.data?.data?.result?.user || null)
      } catch (error) {
        // do something
      }finally {
        setFetching(false)
      }
    },
    []
  )

  return { profile, getProfile, fetching }
}