import { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from "react"
import api from "src/utils/api"

export type PostItem = {
  uuid: string
  file_url: string
  caption: string
  location: string
  created_at: string
  user: {
    username: string
    full_name: string
    avatar: string | null
  }
}

export const PostsContext = createContext<{
  posts: Array<PostItem>
  setPosts: Dispatch<SetStateAction<Array<PostItem>>>,
  fetching: boolean
  setFetching: Dispatch<SetStateAction<boolean>>
}>({
  posts: [],
  setPosts: () => {/**/},
  fetching: true,
  setFetching: () => {/**/}
})

export const PostsPorvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [posts, setPosts] = useState<Array<PostItem>>([])
	const [fetching, setFetching] = useState<boolean>(true)

	return (
		<PostsContext.Provider value={{ posts, setPosts, fetching, setFetching }}>
			{children}
		</PostsContext.Provider>
	)
}

export const usePosts = () => {
  const { setPosts, posts, setFetching, fetching } = useContext(PostsContext)

  const getPosts = useCallback(
    async () => {
      try {
        setFetching(true)
        const response = await api.get<{ data: { result: PostItem[] } }>('/posts')
        setPosts(response?.data?.data?.result || [])
      } catch (error) {
        // do something
      }finally {
        setFetching(false)
      }
    },
    []
  )

  return { posts, getPosts, fetching }
}