import axios from 'axios'
import { getAuthToken } from './token'

const BASE_API = process.env.REACT_APP_BASE_API

const api = axios.create({
  baseURL: BASE_API,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(
  (config) => {
    // Do something before request is sent

    const authToken = getAuthToken()
    if (authToken) config.headers['Authorization'] = 'Bearer ' + authToken

    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

export default api
