import { lazy, memo, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'src/styles/main.scss'
import { AuthProvider } from './hooks/auth';
import { PostsPorvider } from './hooks/posts';

// layouts
const BlankLayout = lazy(() => import('src/layouts/BlankLayout'))
const DefaultLayout = lazy(() => import('src/layouts/DefaultLayout'))

// pages
const Login = lazy(() => import('src/pages/auth/Login'))
const Register = lazy(() => import('src/pages/auth/Register'))
const Home = lazy(() => import('src/pages/home/Home'))
const UserProfile = lazy(() => import('src/pages/user/UserProfile'))

const App: React.FC = () => {
  return (
    <AuthProvider>
      <PostsPorvider>
        <BrowserRouter>
          <Routes>
            <Route element={<Suspense><BlankLayout/></Suspense>}>
              <Route
                path='/login'
                element={<Suspense><Login/></Suspense>}
              />
              <Route
                path='/register'
                element={<Suspense><Register/></Suspense>}
              />
            </Route>
            <Route element={<Suspense><DefaultLayout/></Suspense>}>
              <Route
                index
                element={<Suspense><Home/></Suspense>}
              />
              <Route
                path='/:username'
                element={<Suspense><UserProfile/></Suspense>}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </PostsPorvider>
    </AuthProvider>
  )
}

export default memo(App)
